import format from 'date-fns/format';

// const SLOT_TYPE_FREE = 1;
// const SLOT_TYPE_TAKEN = 1;
// const SLOT_TYPE_TAKEN_BY_ME = 1;
const SLOT_TYPE_DEAD_HOUR = 4;

const AIRFIELD_MAP = {
  LLHA: 'חיפה',
  LLHZ: 'הרצליה',
};

const toDurationStr = duration => {
  if (duration < 3600) {
    return `${duration / 60} דקות`;
  }
  const hours = duration / 3600;
  if (hours === 1) {
    return 'שעה אחת';
  }
  return `${hours} שעות`;
};

const classesForType = type => {
  if (type === SLOT_TYPE_DEAD_HOUR) {
    return 'line-through text-red-300'
  }

  return '';
}

const Slots = ({ slots, airplanes, loading }) => (
  <div className="flex-1">
    {loading && (
      <p className="text-indigo-600 font-medium text-center flex-1">טוען...</p>
    )}

    <div className="flex flex-col sm:flex-wrap sm:flex-row">
      {Object.keys(slots).map(airplaneId => {
        const airplane = airplanes[airplaneId];
        return (
          <div key={airplaneId} className="mb-2 sm:w-1/3 lg:w-1/5 px-2">
            <div className="text-center border-blue-600 border lg:block flex flex-row items-center">
              <div className="font-semibold text-lg flex-grow">{airplane.name}</div>
              <div className="text-base text-gray-600 lg:text-xs lg:p-0 px-4">({AIRFIELD_MAP[airplane.home_airfield]})</div>
            </div>
            {slots[airplaneId].map(({ start, duration, type }) => (
              <div key={`${airplaneId}_${start}`} className="flex">
                <div className={`font-medium ml-2 flex-1 text-center ltr ${classesForType(type)}`}>
                  {format(start * 1000, 'HH:mm')}&nbsp;-&nbsp;
                  {format((start + duration) * 1000, 'HH:mm')}
                </div>
                <div className={`flex-1 ${classesForType(type)}`}>{toDurationStr(duration)}</div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  </div>
);

export default Slots;
