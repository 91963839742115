import { useCallback, useMemo, useState, useEffect } from 'react';
import format from 'date-fns/format';
import { CancelToken, isCancel } from 'axios';

import 'react-datepicker/dist/react-datepicker.css';

import {
  getAuth,
  setAuth,
  createApi,
  removeAuth,
  TIME_UNIT_SLOT,
  DATE_FORMAT,
} from './utils';

import DatePicker from './DatePicker';
import Login from './Login';
import Slots from './Slots';

const App = ({ initialDate }) => {
  const [authHeader, setAuthHeader] = useState(getAuth());
  const [slots, setSlots] = useState({});
  const [airplanes, setAirplanes] = useState({});
  const [date, setDate] = useState(initialDate);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const login = useCallback(
    (number, password) => {
      setAuth(number, password);
      setAuthHeader(getAuth());
    },
    [setAuthHeader]
  );

  const api = useMemo(() => createApi(authHeader), [authHeader]);
  const logout = useCallback(() => {
    removeAuth();
    setAuthHeader(null);
  }, [setAuthHeader]);

  useEffect(() => {
    window.history.pushState({}, '', `?${format(date, DATE_FORMAT)}`);
    setSlots([]);
    setAirplanes({});
  }, [date]);

  useEffect(() => {
    if (api === null) {
      return [];
    }

    setLoading(true);
    const cancelReq = CancelToken.source();
    api
      .get('/', {
        cancelToken: cancelReq.token,
        params: {
          date: format(date, DATE_FORMAT),
        },
      })
      .then(({ data }) => {
        setError('');
        setLastUpdate(new Date());
        setAirplanes(data.airplanes);
        setSlots(data.slots);
        setLoading(false);
      })
      .catch(err => {
        if (isCancel(err)) {
          return;
        }
        if (err?.response?.status === 401) {
          return logout();
        }
        setError(err.response.data.error);
        setLoading(false);
      });

    return () => cancelReq.cancel('');
  }, [api, date, logout, setError]);

  const timeUnit = TIME_UNIT_SLOT;

  if (authHeader === null) {
    return <Login loginFn={login} />;
  }

  return (
    <div className="min-h-screen bg-gray-100 py-2 px-2 flex flex-col justify-center sm:py-12">
      <header className="flex w-full flex-shrink-0 justify-between mb-2">
        <div className="text-sm self-center">
          רענון אחרון: {format(lastUpdate, 'yyyy-MM-dd HH:mm:ss')}
        </div>
        <div>
          <button
            className="py-1 px-2 bg-blue-500 text-white font-medium rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
            onClick={logout}
          >
            התנתק
          </button>
        </div>
      </header>
      <div className="text-2xl font-semibold text-center text-blue-400 mb-2">
        מטוסים זמינים
      </div>
      <DatePicker date={date} setDate={setDate} />
      {error ? (
        <p className="text-red-600 font-medium text-center flex-1">
          <strong>שגיאה</strong>: {error}
        </p>
      ) : (
        <>
          <div className="text-sm font-light text-center text-blue-400 my-2">
            לתיאום חייגו למבצעים{' '}
            <a href="tel:+97297735594,1" className="underline">
              09-7735594
            </a>
          </div>
          <Slots
            slots={slots}
            airplanes={airplanes}
            timeUnit={timeUnit}
            loading={loading}
          />
        </>
      )}
    </div>
  );
};

export default App;
