import { useMemo } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import he from 'date-fns/locale/he';
import sub from 'date-fns/sub';
import add from 'date-fns/add';
import format from 'date-fns/format';

import { maxDate, isValidDate } from './utils';

const DISPLAY_FORMAT = 'dd/MM/yyyy';

registerLocale('he', he);

const DatePicker = ({ date, setDate }) => {
  const yesterday = useMemo(() => {
    const yesterday = sub(date, { days: 1 });
    return isValidDate(yesterday) ? yesterday : null;
  }, [date]);

  const tomorrow = useMemo(() => {
    const tomorrow = add(date, { days: 1 });
    return isValidDate(tomorrow) ? tomorrow : null;
  }, [date]);

  return (
    <div className="flex mx-auto">
      <button
        type="button"
        className={`px-4 cursor-pointer bg-blue-400 rounded-xl hover:bg-blue-600 text-white sm:w-36 ${
          yesterday === null && 'invisible'
        }`}
        onClick={() => setDate(yesterday)}
      >
        &lt;&nbsp;
        <span className="hidden sm:inline-block">
          {yesterday && format(yesterday, DISPLAY_FORMAT)}
        </span>
      </button>
      <ReactDatePicker
        locale="he"
        selected={date}
        onChange={setDate}
        startDate={new Date()}
        endDate={maxDate()}
        className="mx-2 text-center cursor-pointer bg-blue-400 border-blue-500 rounded-xl hover:bg-blue-600 text-white"
        dateFormat={DISPLAY_FORMAT}
        disabledKeyboardNavigation
        todayButton="היום"
      />
      <button
        type="button"
        className={`px-4 cursor-pointer bg-blue-400 rounded-xl hover:bg-blue-600 text-white sm:w-36 ${
          tomorrow === null && 'invisible'
        }`}
        onClick={() => setDate(tomorrow)}
      >
        <span className="hidden sm:inline-block">
          {tomorrow && format(tomorrow, DISPLAY_FORMAT)}
        </span>
        &nbsp;&gt;
      </button>
    </div>
  );
};

export default DatePicker;
