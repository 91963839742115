import axios from 'axios';
import add from 'date-fns/add';
import sub from 'date-fns/sub';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';

const USER_KEY = 'sky10.calendar';

export const DATE_FORMAT = 'yyyy-MM-dd';
export const TIME_UNIT_SLOT = 30; // 30 minutes
export const SLOT_FREE = 1;
export const SLOT_TAKEN = 2;
export const SLOT_TAKEN_BY_ME = 3;

export const maxDate = () => add(new Date(), { months: 1 });
export const isValidDate = date =>
  isBefore(date, maxDate()) && isAfter(date, sub(new Date(), { days: 1 }));

export const getAuth = () => {
  try {
    return localStorage.getItem(USER_KEY) ?? null;
  } catch {
    return null;
  }
};

export const setAuth = (number, password) => {
  localStorage.setItem(USER_KEY, `${number}:${password}`);
};

export const removeAuth = () => {
  localStorage.removeItem(USER_KEY);
};

export const createApi = authHeader => {
  if (authHeader === null) {
    return null;
  }
  return axios.create({
    baseURL: '/api/',
    headers: {
      Authorization: `Bearer ${authHeader}`,
    },
  });
};
