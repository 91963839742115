import React from 'react';
import ReactDOM from 'react-dom';
import parseISO from 'date-fns/parseISO';

import './index.css';
import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { isValidDate } from './utils';

const params = {
  initialDate: new Date(),
};
try {
  const dateFromHash = parseISO(document.location.search.substr(1));
  if (isValidDate(dateFromHash)) {
    params.initialDate = dateFromHash;
  }
} catch {}

ReactDOM.render(
  <React.StrictMode>
    <App {...params} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
