import { useState, useCallback } from 'react';
import { LockClosedIcon } from '@heroicons/react/solid';

import { createApi } from './utils';

const Login = ({ loginFn }) => {
  const [invalid, setInvalid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [num, setNum] = useState('');
  const [license, setLicense] = useState('');
  const submit = useCallback(
    form => {
      form.preventDefault();
      setSubmitting(true);
      setInvalid(false);

      createApi(`${num}:${license}`)
        .head('/')
        .then(() => {
          setSubmitting(false);
          loginFn(num, license);
        })
        .catch(() => {
          setInvalid(true);
          setSubmitting(false);
        });
    },
    [num, license, loginFn, setSubmitting]
  );

  return (
    <div className="min-h-screen flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            סקאי תעופה - לוח מטוסים
          </h2>
          <p className="mt-2 text-center text-sm font-medium text-blue-600 hover:text-blue-500">
            התחברו באמצעות תעודת זהות ומספר רישיון
          </p>
          {invalid && (
            <p className="mt-2 text-center text-md font-medium text-red-600">
              שגיאה: פרטים לא נכונים. נסו שוב
            </p>
          )}
        </div>
        <form className="mt-8 space-y-6" onSubmit={submit}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="number" className="sr-only">
                תעודת זהות
              </label>
              <input
                id="number"
                name="number"
                type="number"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="תעודת זהות"
                value={num}
                onChange={e => setNum(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="license" className="sr-only">
                מספר רישיון
              </label>
              <input
                id="license"
                name="license"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="מספר רישיון"
                value={license}
                onChange={e => setLicense(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={submitting}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon className="h-5 w-5 text-blue-500 group-hover:text-blue-400" />
              </span>
              כניסה
            </button>
          </div>
        </form>
        <div>
          <p className="mt-2 text-center text-sm font-medium text-blue-600 hover:text-blue-500">
            בעיות בהתחברות? פנו למבצעים{' '}
            <a href="tel:+97297735594,1" className="underline">
              09-7735594
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
